import React, { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import {
  TextField,
  makeStyles,
  MenuItem,
  FormControl,
  Select,
  FormHelperText,
  Input,
} from "@material-ui/core";
import { convertPaisaToRupee } from "../../../Utility/paramsConvert";

import { blockStyle, BootstrapButton } from "../../../Utility/Style";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Box from "@material-ui/core/Box";
import Fade from "@material-ui/core/Fade";
import axios from "axios";
import config from "../../../Utility/config";
import { useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import Grid from "@material-ui/core/Grid";
import { getDateFormat } from "../../../Utility/paramsConvert";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert } from "@material-ui/lab";
import {
  cancellationOptions,
  orderStatusOptions,
  orderStatus,
  codReceivedByOptions,
  constSuccess,
  orderStatusHelperText,
  cancellationNoteHelperText,
  codReceivedHelperText,
} from "../../../Utility/stringConstant";

function EditOrderDetailsModal({
  editOrderDataDisplayModalOpen,
  modalOrderData,
  onHandleCloseModalWithEditOrder,
}) {
  const useStyles = makeStyles(blockStyle);
  const classes = useStyles();

  const [cpInternalNote, setCpInternalNote] = useState(null);
  const [cancellationNote, setCancellationNote] = useState(null);
  const [orderStatusValue, setOrderStatusValue] = useState(null);
  const [orderCodReceivedByValue, setOrderCodReceivedByValue] = useState(null);
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("");
  const { authToken } = useParams();
  const authorization = "JWT " + { authToken }.authToken;
  const longOrderID = modalOrderData.order_id;
  const ordersURL = config.getOrderListUrl + longOrderID;
  const handleValuChangeCPInternalNote = (cpInternalNote) => {
    setCpInternalNote(cpInternalNote.target.value);
  };

  const handleValueChangeCancellationNote = (cancellationNote) => {
    setCancellationNote(cancellationNote.target.value);
  };
  const handleValueChangeOrderStatus = (orderStatusValue) => {
    setOrderStatusValue(orderStatusValue.target.value);
  };
  const handleOrderCodReceivedByValue = (orderCodReceivedByValue) => {
    setOrderCodReceivedByValue(orderCodReceivedByValue.target.value);
  };
  const handleSuccessSnackbarOpen = () => {
    setSuccessSnackbarOpen(true);
  };
  const handleSuccessSnackbarClose = () => {
    setSuccessSnackbarOpen(false);
    if (snackbarType === "success") {
      window.location.reload();
    }
  };
  const handleExecutionAfterSuccessfulUpdate = (alertMessage) => {
    onHandleCloseModalWithEditOrder();
    setOrderStatusValue(null);
    showSnackbarAlertMessage(alertMessage, "success");
    if (cpInternalNote != null) {
      editOrderDetailsInternalNoteAPI();
    }
  };
  const showSnackbarAlertMessage = (alertMessage, alertType) => {
    setAlertMessage(alertMessage);
    setSnackbarType(alertType);
    handleSuccessSnackbarOpen();
  };
  const convertToTitleCase = (word) => {
    if (word != null && word != "") {
      if (word.includes("_")) {
        let splitted_words = word.split("_");
        let words = "";
        splitted_words.map((splitted_word) => {
          words = words + " " + convertToTitleCase(splitted_word);
        });
        return words;
      }
      return word[0].toUpperCase() + word.slice(1).toLowerCase();
    }
    return "";
  };

  const checkValidValueOfData = (parentdata) => {
    if (parentdata === (null || undefined || "")) {
      return false;
    } else {
      return true;
    }
  };

  const validations = () => {
    let orderStatusInAPI = modalOrderData.order_status;
    if (orderStatusValue !== null) {
      if (orderStatusValue === orderStatus.provider_cancelled) {
        if (cancellationNote == null) {
          showSnackbarAlertMessage("Please select Cancellation note!", "info");
          return false;
        } else if (
          orderStatusInAPI === orderStatusValue.toUpperCase() ||
          orderStatusInAPI === orderStatusOptions[4].value
        ) {
          showSnackbarAlertMessage("Already Order Cancelled", "info");
          return false;
        }
        return true;
      } else if (
        orderStatusValue === orderStatus.order_completed &&
        orderStatusInAPI !== orderStatus.order_completed
      ) {
        if (modalOrderData.payment_info.status === constSuccess) {
          return true;
        }
        if (orderCodReceivedByValue !== null) {
          if (orderStatusInAPI !== orderStatus.order_completed) {
            return true;
          } else {
            showSnackbarAlertMessage("Already Order Completed", "info");
            return false;
          }
        } else {
          showSnackbarAlertMessage(
            "Please select COD received by value",
            "info"
          );
          return false;
        }
      } else if (orderStatusInAPI === orderStatus.order_completed) {
        showSnackbarAlertMessage("Already Order Completed", "info");
        return false;
      }
    } else {
      showSnackbarAlertMessage("Please fill mandatory fields", "info");
    }

    return false;
  };
  const completeOrderAPI = () => {
    const data = {
      cod_received_via: orderCodReceivedByValue,
    };
    axios
      .post(
        ordersURL + "/complete",
        modalOrderData.payment_info.payment_type === "COD" ? data : null,
        {
          headers: {
            Authorization: authorization,
          },
        }
      )
      .then((response) => {
        handleExecutionAfterSuccessfulUpdate(
          "Successfully updated. Please close this to refresh."
        );
        if (orderCodReceivedByValue !== null) {
          setOrderCodReceivedByValue(null);
        }
      })
      .catch(function (error) {
        showSnackbarAlertMessage(error.response.data.message, "info");
      });
  };
  const markAsReadyAPI = () => {
    axios
      .post(ordersURL + "/ready", null, {
        headers: {
          Authorization: authorization,
        },
      })
      .then((response) => {
        handleExecutionAfterSuccessfulUpdate(
          "Successfully updated. Please close this to refresh."
        );
      })
      .catch(function (error) {
        showSnackbarAlertMessage(error.response.data.message, "info");
      });
  };
  const merchantAcceptOrderByID = () => {
    axios
      .post(ordersURL + "/accept", null, {
        headers: {
          Authorization: authorization,
        },
      })
      .then((response) => {
        handleExecutionAfterSuccessfulUpdate(
          "Successfully updated. Please close this to refresh."
        );
      })
      .catch(function (error) {
        showSnackbarAlertMessage(error.response.data.message, "info");
      });
  };
  const cancelOrderAPI = () => {
    const data = {
      cancellation_note: cancellationNote,
    };
    axios
      .post(ordersURL + "/cancel", data, {
        headers: {
          Authorization: authorization,
        },
      })
      .then((response) => {
        handleExecutionAfterSuccessfulUpdate(
          "Successfully updated. Please close this to refresh."
        );
        setCancellationNote(null);
      })
      .catch(function (error) {
        showSnackbarAlertMessage(error.response.data.message, "info");
      });
  };
  const editOrderDetailsInternalNoteAPI = () => {
    const data = {
      cp_internal_note: cpInternalNote,
    };
    axios
      .put(ordersURL + "/internal_note", data, {
        headers: {
          Authorization: authorization,
        },
      })
      .then((response) => {
        handleExecutionAfterSuccessfulUpdate(
          "Successfully updated. Please close this to refresh."
        );
        setCpInternalNote(null);
      })
      .catch(function (error) {
        showSnackbarAlertMessage(error.response.data.message, "info");
      });
  };

  const editFieldValidations = () => {
    if (orderStatusValue != null) {
      switch (orderStatusValue) {
        case orderStatusOptions[4].value:
          if (validations()) {
            cancelOrderAPI();
          }
          break;
        case orderStatusOptions[3].value:
          if (validations()) {
            completeOrderAPI();
          }
          break;
        case orderStatusOptions[1].value:
          merchantAcceptOrderByID();
          break;
        case orderStatusOptions[2].value:
          markAsReadyAPI();
          break;
        default:
          showSnackbarAlertMessage("Please fill mandatory fields", "info");
          break;
      }
    } else {
      showSnackbarAlertMessage("Please fill mandatory fields", "info");
    }
  };
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={successSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleSuccessSnackbarClose}
      >
        <Alert onClose={handleSuccessSnackbarClose} severity={snackbarType}>
          {alertMessage}
        </Alert>
      </Snackbar>
      <Modal
        style={{ width: "100%" }}
        open={editOrderDataDisplayModalOpen}
        onClose={onHandleCloseModalWithEditOrder}
        className={classes.modal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={editOrderDataDisplayModalOpen}>
          <div className={classes.modalOuter}>
            <div className={classes.modalPaper}>
              <Box display="flex" justifyContent="flex-end">
                <IconButton onClick={onHandleCloseModalWithEditOrder}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <Box>
                <h2>Edit Order Details</h2>
              </Box>
              <hr />
              <Form onSubmit={validations}>
                <Box display="flex">
                  <Grid item xs={6} md={6} style={{ display: "flex" }}>
                    <p>
                      <strong>Order Created Date : </strong>
                    </p>
                    <p style={{ marginLeft: "20px" }}>
                      {checkValidValueOfData(modalOrderData)
                        ? getDateFormat(modalOrderData.created)
                        : "Not Available"}
                    </p>
                  </Grid>
                  <Grid item xs={6} md={6} style={{ display: "flex" }}>
                    <p>
                      <strong>Order Last Update Date : </strong>
                    </p>
                    <p style={{ marginLeft: "20px" }}>
                      {checkValidValueOfData(modalOrderData)
                        ? getDateFormat(modalOrderData.modified)
                        : "Not Available"}
                    </p>
                  </Grid>
                </Box>
                <Box display="flex">
                  <Grid item xs={6} md={6} style={{ display: "flex" }}>
                    <p>
                      <strong>Order ID : </strong>
                    </p>
                    <p style={{ marginLeft: "20px" }}>
                      {checkValidValueOfData(modalOrderData)
                        ? modalOrderData.order_short_number
                        : "Not Available"}
                    </p>
                  </Grid>
                  <Grid item xs={6} md={6} style={{ display: "flex" }}>
                    <p>
                      <strong>Payment Status : </strong>
                    </p>
                    <p style={{ marginLeft: "20px" }}>
                      {checkValidValueOfData(modalOrderData)
                        ? convertToTitleCase(modalOrderData.payment_info.status)
                        : "Not Available"}
                    </p>
                  </Grid>
                </Box>
                <Box display="flex">
                  <Grid item xs={6} md={6} style={{ display: "flex" }}>
                    <p>
                      <strong>Status : </strong>
                    </p>
                    <p style={{ marginLeft: "20px" }}>
                      {checkValidValueOfData(modalOrderData)
                        ? convertToTitleCase(modalOrderData.order_status)
                        : "Not Available"}
                    </p>
                  </Grid>
                  <Grid item xs={6} md={6} style={{ display: "flex" }}>
                    <p>
                      <strong>Payment Mode : </strong>
                    </p>
                    <p style={{ marginLeft: "20px" }}>
                      {checkValidValueOfData(modalOrderData)
                        ? modalOrderData.payment_info.via
                        : "Not Available"}
                    </p>
                  </Grid>
                </Box>
                <Box display="flex">
                  <Grid item xs={6} md={6} style={{ display: "flex" }}>
                    <p>
                      <strong>Seller : </strong>
                    </p>
                    <p style={{ marginLeft: "20px" }}>
                      {checkValidValueOfData(modalOrderData)
                        ? modalOrderData.business_name
                        : "Not Available"}
                    </p>
                  </Grid>
                  <Grid item xs={6} md={6} style={{ display: "flex" }}>
                    <p>
                      <strong>Order Amount : </strong>
                    </p>
                    <p style={{ marginLeft: "20px" }}>
                      {checkValidValueOfData(modalOrderData)
                        ? convertPaisaToRupee(
                            modalOrderData.payment_info.amount
                          )
                        : "Not Available"}
                    </p>
                  </Grid>
                </Box>
                <Box display="flex">
                  <Grid item xs={6} md={6} style={{ display: "flex" }}>
                    <p>
                      <strong>Customer Name : </strong>
                    </p>
                    <p style={{ marginLeft: "20px" }}>
                      {checkValidValueOfData(modalOrderData)
                        ? convertToTitleCase(modalOrderData.customer_name)
                        : "Not available"}
                    </p>
                  </Grid>
                  <Grid item xs={6} md={6} style={{ display: "flex" }}>
                    <p>
                      <strong>Delivery Type : </strong>
                    </p>
                    <p style={{ marginLeft: "20px" }}>
                      {checkValidValueOfData(modalOrderData)
                        ? convertToTitleCase(modalOrderData.delivery_type)
                        : "Not Available"}
                    </p>
                  </Grid>
                </Box>
                <Box display="flex">
                  <Grid item xs={6} md={6} style={{ display: "flex" }}>
                    <p>
                      <strong>Customer Number : </strong>
                    </p>
                    <p style={{ marginLeft: "20px" }}>
                      {checkValidValueOfData(modalOrderData)
                        ? modalOrderData.customer_phones !== null
                          ? modalOrderData.customer_phones[0]
                          : ""
                        : "Not Available"}
                    </p>
                  </Grid>
                  <Grid item xs={6} md={6} style={{ display: "flex" }}>
                    <p>
                      <strong>Delivery charges : </strong>
                    </p>
                    <p style={{ marginLeft: "20px" }}>
                      {checkValidValueOfData(modalOrderData)
                        ? convertPaisaToRupee(modalOrderData.delivery_charges)
                        : "Not Available"}
                    </p>
                  </Grid>
                </Box>
                <Box display="flex">
                  <Grid item xs={6} md={6} style={{ display: "flex" }}>
                    <p>
                      <strong>Customer Address : </strong>
                    </p>
                    <p style={{ marginLeft: "20px" }}>
                      {checkValidValueOfData(modalOrderData)
                        ? modalOrderData.delivery_address !== null
                          ? convertToTitleCase(
                              modalOrderData.delivery_address.pretty_address
                            )
                          : "Self pickup order"
                        : "Not Avaialable"}
                    </p>
                  </Grid>
                  <Grid item xs={6} md={6} style={{ display: "flex" }}>
                    <p>
                      <strong>Other Charges : </strong>
                    </p>
                    <p style={{ marginLeft: "20px" }}>
                      {checkValidValueOfData(modalOrderData)
                        ? convertPaisaToRupee(modalOrderData.other_charges)
                        : "Not Available"}
                    </p>
                  </Grid>
                </Box>
                <Box display="flex">
                  <Grid item xs={6} md={6} style={{ display: "flex" }}>
                    <p>
                      <strong>Item Total : </strong>
                    </p>
                    <p style={{ marginLeft: "20px" }}>
                      {checkValidValueOfData(modalOrderData)
                        ? convertPaisaToRupee(modalOrderData.item_total)
                        : "Not Avaialable"}
                    </p>
                  </Grid>
                  <Grid item xs={6} md={6} style={{ display: "flex" }}>
                    <p>
                      <strong>Delivery Agent : </strong>
                    </p>
                    <p style={{ marginLeft: "20px" }}>
                      {checkValidValueOfData(modalOrderData)
                        ? modalOrderData.da_info !== {}
                          ? modalOrderData.da_info.profile_name
                          : ""
                        : "Not Available"}
                    </p>
                  </Grid>
                </Box>

                <Box display="flex" mt={2}>
                  <Grid item xs={6} md={6} style={{}}>
                    <Select
                      id="status"
                      native
                      required
                      variant="outlined"
                      label="Order Status"
                      value={orderStatusValue}
                      onChange={handleValueChangeOrderStatus}
                    >
                      {orderStatusOptions.map((option) => (
                        <option value={option.value}>{option.label}</option>
                      ))}
                    </Select>
                    <FormHelperText>{orderStatusHelperText}</FormHelperText>
                  </Grid>
                  <Grid item xs={6} md={6} style={{ display: "flex" }}>
                    <Box>
                      <TextField
                        id="cp-internal-note"
                        label="CP Internal Note"
                        variant="outlined"
                        placeholder="Enter CP Internal Note"
                        onChange={handleValuChangeCPInternalNote}
                      />
                    </Box>
                  </Grid>
                </Box>
                <Box display="flex" mt={2}>
                  <Grid item xs={6} md={6} style={{ display: "flex" }}>
                    <FormControl>
                      <Select
                        id="cancellation-note"
                        native
                        required
                        fullWidth
                        disabled={
                          orderStatusValue === orderStatus.provider_cancelled
                            ? false
                            : true
                        }
                        variant="outlined"
                        label="Cancellation Note"
                        onChange={handleValueChangeCancellationNote}
                        value={cancellationNote}
                      >
                        {cancellationOptions.map((option) => (
                          <option value={option.value}>{option.label}</option>
                        ))}
                      </Select>
                      <FormHelperText>
                        {cancellationNoteHelperText}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} md={6} style={{ display: "flex" }}>
                    <FormControl>
                      <Select
                        id="cod-received-by"
                        native
                        required
                        fullWidth
                        disabled={
                          orderStatusValue === orderStatus.order_completed &&
                          modalOrderData.payment_info.status !== constSuccess
                            ? false
                            : true
                        }
                        variant="outlined"
                        label="COD Received by"
                        onChange={handleOrderCodReceivedByValue}
                        value={orderCodReceivedByValue}
                      >
                        {codReceivedByOptions.map((option) => (
                          <option value={option.value}>{option.label}</option>
                        ))}
                      </Select>
                      <FormHelperText>{codReceivedHelperText}</FormHelperText>
                    </FormControl>
                  </Grid>
                </Box>
                <Box className={classes.outerBox}>
                  <Box className={classes.innerBox}>
                    <BootstrapButton
                      variant="contained"
                      color="primary"
                      onClick={editFieldValidations}
                    >
                      Update
                    </BootstrapButton>
                  </Box>
                </Box>
              </Form>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
export default EditOrderDetailsModal;
