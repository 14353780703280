import React, { useState, useEffect, useRef } from "react";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { TextField, makeStyles } from "@material-ui/core";
import { blockStyle, BootstrapButton } from "../../../Utility/Style";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import axios from "axios";
import config from "../../../Utility/config";
import { useParams } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert } from "@material-ui/lab";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
function SendNotificationModal({
  sendNotificationDataModalOpen,
  onHandleCloseModalWithNotification,
  customerPhoneNumber,
}) {
  const useStyles = makeStyles(blockStyle);
  const classes = useStyles();

  const [textValueTitle, setTextValueTitle] = useState("");
  const [textValueNotificationMessage, setTextValueNotificationMessage] =
    useState("");
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("");

  const { circleId, authToken } = useParams();

  const handleChangeTitleValue = (e) => {
    setTextValueTitle(e.target.value);
  };
  const handleChangeMessageValue = (e) => {
    setTextValueNotificationMessage(e.target.value);
  };
  const handleSuccessSnackbarOpen = () => {
    setSuccessSnackbarOpen(true);
  };
  const handleSuccessSnackbarClose = () => {
    setSuccessSnackbarOpen(false);
  };

  const checkMessageValidation = () => {
    if (textValueTitle !== "" && textValueNotificationMessage !== "") {
      sendNotificationAPI();
    } else {
      setAlertMessage("Please fill mandatory fields");
      setSnackbarType("info");
      handleSuccessSnackbarOpen();
    }
  };

  const sendNotificationAPI = () => {
    const data = {
      title: textValueTitle,
      body: textValueNotificationMessage,
      customer_phones: [customerPhoneNumber],
    };
    axios
      .post(
        config.sendNotificationUrl + { circleId }.circleId + "/notification",
        data,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "JWT " + { authToken }.authToken,
          },
        }
      )
      .then((response) => {
        onHandleCloseModalWithNotification();
        setTextValueTitle("");
        setTextValueNotificationMessage("");
      })
      .catch(function (error) {
        alert("Unauthorize or slow internet");
      });
  };
  return (
    <>
      <Modal
        open={sendNotificationDataModalOpen}
        onClose={onHandleCloseModalWithNotification}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={successSnackbarOpen}
            autoHideDuration={6000}
            onClose={handleSuccessSnackbarClose}
          >
            <Alert onClose={handleSuccessSnackbarClose} severity={snackbarType}>
              {alertMessage}
            </Alert>
          </Snackbar>
          <div className={classes.modalOuter}>
            <div className={classes.modalPaper}>
              <Box display="flex" justifyContent="flex-end">
                <IconButton onClick={onHandleCloseModalWithNotification}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <Box className={classes.outerBox}>
                <Box className={classes.innerBox}>
                  <TextField
                    id="title"
                    label="Title"
                    variant="outlined"
                    placeholder="Enter Title"
                    required
                    onChange={handleChangeTitleValue}
                  />
                </Box>
              </Box>
              <Box className={classes.outerBox}>
                <Box className={classes.innerBox}>
                  <TextField
                    id="message"
                    multiline
                    label="Message"
                    variant="outlined"
                    placeholder="Enter Message"
                    required
                    onChange={handleChangeMessageValue}
                  />
                </Box>
              </Box>
              <Box className={classes.outerBox}>
                <Box className={classes.innerBox}>
                  <BootstrapButton
                    variant="contained"
                    onClick={checkMessageValidation}
                  >
                    Send
                  </BootstrapButton>
                </Box>
              </Box>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}
export default SendNotificationModal;
