import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { Link } from "react-router-dom";
import { navbarHeader } from "../../Utility/stringConstant";
import { Box, Button, CircularProgress, Divider } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  navbarMenus: {
    display: "flex",
    alignItems: "center",
  },
}));
export default function Navbar({
  isNewNotificationPopped,
  onSetNewNotificationPopped,
  downloadData,
  setDownloadData,
  processedQueuedResponse,
}) {
  const classes = useStyles();
  const { circleId, authToken } = useParams();
  const [displayProcessing, setDisplayProcessing] = useState(false);
  const [displayQueued, setDisplayQueued] = useState(false);
  // const dataProcess = downloadData;

  return (
    <div className={classes.grow}>
      <AppBar position="static" style={{ background: "#DB8F48" }}>
        <Toolbar>
          <Link
            style={{ textDecoration: "none" }}
            to={`/${{ circleId }.circleId}/${{ authToken }.authToken}`}
          >
            <Typography
              style={{ color: "#FFF" }}
              className={classes.title}
              variant="h6"
              noWrap
            >
              {navbarHeader[0]}
            </Typography>
          </Link>
          <div className={classes.search}></div>
          <div className={classes.grow} />
          <div className={classes.navbarMenus}>
            <div style={{ display: "flex", alignItems: "center" }}>
              {/* <Link
              style={{ textDecoration: "none" }}
              to={`/${{ circleId }.circleId}/${{ authToken }.authToken}`}
            >
              <Typography
                style={{ color: "#FFF" }}
                className={classes.title}
                variant="h6"
                noWrap
              >
                {navbarHeader[1]}
              </Typography>
            </Link>
            <Link
              style={{ textDecoration: "none" }}
              to={`/${{ circleId }.circleId}/${
                { authToken }.authToken
              }/queries`}
            >
              <Typography
                style={{ color: "#FFF", margin: "0 24px" }}
                className={classes.title}
                variant="h6"
                noWrap
              >
                {navbarHeader[2]}
              </Typography>
            </Link> */}
              {/* {processedQueuedResponse && ( */}
              <Typography>Order Reports:</Typography>
              <Box style={{ paddingRight: 150 }}>
                <Box
                  style={{
                    position: "relative",
                    display: "inline-block",
                  }}
                  onMouseEnter={() => setDisplayProcessing(!displayProcessing)}
                  onMouseLeave={() => setDisplayProcessing(!displayProcessing)}
                >
                  <Button
                    style={{
                      color: "white",
                      padding: "16px",
                      fontSize: "16px",
                      border: "none",
                      textTransform: "capitalize",
                    }}
                  >
                    {/* {processedQueuedResponse?.data.processing?.items && ( */}
                    <Box style={{ display: "flex", gap: 10 }}>
                      <Typography variant="body1">
                        {processedQueuedResponse?.data.processing?.count > 0 ? (
                          <Typography>
                            {processedQueuedResponse?.data.processing?.count}
                          </Typography>
                        ) : (
                          <Typography>0</Typography>
                        )}
                      </Typography>
                      <Typography variant="body1">Processing</Typography>
                    </Box>
                    {/* )} */}
                  </Button>
                  <Box
                    style={{
                      display: `${displayProcessing ? "block" : "none"}`,
                      position: "absolute",
                      top: 56,
                      height: "300px",
                      width: "300px",
                      color: "black",
                      backgroundColor: "#f1f1f1",
                      boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
                      zIndex: 1,
                      overflow: "auto",
                    }}
                  >
                    {processedQueuedResponse?.data.processing?.items ? (
                      <>
                        {processedQueuedResponse?.data.processing?.items?.map(
                          (item) => {
                            return (
                              <Box>
                                <Typography
                                  variant="body1"
                                  style={{ padding: "10px" }}
                                >
                                  {item.key}
                                </Typography>
                                <Divider />
                              </Box>
                            );
                          }
                        )}
                      </>
                    ) : (
                      <Typography
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "",
                        }}
                      >
                        No Records available
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box
                  style={{ position: "relative", display: "inline-block" }}
                  onMouseEnter={() => setDisplayQueued(!displayQueued)}
                  onMouseLeave={() => setDisplayQueued(!displayQueued)}
                >
                  <Button
                    style={{
                      color: "white",
                      padding: "16px",
                      fontSize: "16px",
                      border: "none",
                      textTransform: "capitalize",
                    }}
                  >
                    {/* {processedQueuedResponse?.data.pending?.items && ( */}
                    <Box style={{ display: "flex", gap: 10 }}>
                      <Typography variant="h6">
                        {processedQueuedResponse?.data.pending?.count > 0 ? (
                          <Typography>
                            {processedQueuedResponse?.data.pending?.count}
                          </Typography>
                        ) : (
                          <Typography>0</Typography>
                        )}
                      </Typography>
                      <Typography variant="body1">Queued</Typography>
                    </Box>
                    {/* )} */}
                  </Button>
                  <Box
                    style={{
                      display: `${displayQueued ? "block" : "none"}`,
                      position: "absolute",
                      top: 56,
                      height: "300px",
                      width: "300px",
                      color: "black",
                      backgroundColor: "#f1f1f1",
                      boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
                      zIndex: 1,
                    }}
                  >
                    {processedQueuedResponse?.data.pending?.items ? (
                      <>
                        {processedQueuedResponse?.data.pending?.items?.map(
                          (item) => {
                            return (
                              <Box>
                                <Typography
                                  variant="body1"
                                  style={{ padding: "10px" }}
                                >
                                  {item.key}
                                </Typography>
                                <Divider />
                              </Box>
                            );
                          }
                        )}
                      </>
                    ) : (
                      <Typography
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "",
                        }}
                      >
                        No Records available
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
              {/* )} */}

              <Link
                to={`/${{ circleId }.circleId}/${
                  { authToken }.authToken
                }/notification`}
              >
                <IconButton
                  style={{ color: "#FFF", marginLeft: "16px" }}
                  aria-label="show 17 new notifications"
                  color="inherit"
                  onClick={() => onSetNewNotificationPopped(true)}
                >
                  <Badge
                    variant="dot"
                    invisible={isNewNotificationPopped}
                    color="secondary"
                  >
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
              </Link>
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}
