import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/messaging";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Pagination } from "@material-ui/lab";
import FilterListIcon from "@material-ui/icons/FilterList";
import Box from "@material-ui/core/Box";
import RefreshIcon from "@material-ui/icons/Refresh";
import GetAppIcon from "@material-ui/icons/GetApp";
import InputBox from "./HomepageComponent/InputBox";
import VisibilityIcon from "@material-ui/icons/Visibility";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import axios from "axios";
import Counter from "./HomepageComponent/Counter";
import OrderModal from "./HomepageComponent/OrderModal";
import Navbar from "../Navbar/Navbar";
import { blockStyle, BootstrapButton } from "../../Utility/Style";
import config from "../../Utility/config";
import FiltersCollapse from "./HomepageComponent/FiltersCollapse";
import { getDateEpoch } from "../../Utility/paramsConvert";
import { homepageTableColumns } from "../../Utility/filterList";
import { getClientToken, deleteFcmTokenInstance } from "../../firebase";
import {
  getCustomerAddress,
  getDateFormat,
  getDeliveryAgentName,
  differenceBetweenOrderCreatedOrderUpdated,
  removeUnderscoreConvertInSmallcase,
  convertPaisaToRupee,
} from "../../Utility/paramsConvert";
import EyeButtonDropdown from "./HomepageComponent/EyeButtonDropdown";
import {
  constCustomerPendingStatus,
  constTrue,
  constFalse,
  constNotAvailable,
  constHelpText,
} from "../../Utility/stringConstant";
import { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";

const useStyles = makeStyles(blockStyle);
var fileDownload = require("js-file-download");
let is_ondc_order_conversion = (is_ondc_order) => {
  if (is_ondc_order) return constTrue;
  return constFalse;
};
const Content = ({ isNewNotificationPopped, onSetNewNotificationPopped }) => {
  const classes = useStyles();

  const [tableData, setTableData] = useState([]);

  const [orderCount, setorderCount] = useState(0);
  const [filterCompleted, setFilterCompleted] = useState(true);
  const [page, setPage] = useState(1);
  const { circleId, authToken } = useParams();
  const [activeOrderFilter, setActiveOrderFilter] = useState([]);
  const [activePaymentFilter, setActivePaymentFilter] = useState([]);
  const [activeDeliveryFilter, setActiveDeliveryFilter] = useState([]);
  const [activeDaNameFilter, setActiveDaNameFilter] = useState(null);
  const [fromEpochDate, setFromEpochDate] = useState();
  const [toEpochDate, setToEpochDate] = useState();
  const [fromEpochDateCreated, setFromEpochDateCreated] = useState();
  const [toEpochDateCreated, setToEpochDateCreated] = useState();
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [fromDateCreated, setFromDateCreated] = useState(null);
  const [toDateCreated, setToDateCreated] = useState(null);
  const [textValueBusinessName, setTextValueBusinessName] = useState("");
  const [textValuePhoneNumber, setTextValuePhoneNumber] = useState("");
  const [textValueTitle, setTextValueTitle] = useState("");
  const [textValueCustomerPhoneNumber, setTextValueCustomerPhoneNumber] =
    useState("");
  const [textValueNotificationMessage, setTextValueNotificationMessage] =
    useState("");
  const [textValueOrder, setTextValueOrder] = useState("");
  const clickedRefFrom = useRef(false);
  const clickedRefTo = useRef(false);
  const clickedRefFromCreated = useRef(false);
  const clickedRefToCreated = useRef(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [eyeButtonDropdownIsOpen, setEyeButtonDropdownIsOpen] =
    React.useState(false);
  const [orderDataDisplayModalOpen, setOrderDataDisplayModalOpen] =
    useState(false);

  const [modalOrderData, setModalOrderData] = useState("");
  const eyeButonDropdownAnchorRef = useRef(null);
  const eyeButtonDropdownPrevOpen = useRef(eyeButtonDropdownIsOpen);
  const [copyText, setCopyText] = useState("Copy");

  const [downloadData, setDownloadData] = useState(false);
  const [processedQueuedResponse, setProcessedQueuedResponse] = useState();

  if (firebase.messaging.isSupported()) {
    getClientToken();
  }
  /**
   * @param {Array} filter - This is containing array of filters from order status
   */
  const handleOrderFilterChange = (filter) => {
    setPage(1);
    setActiveOrderFilter(
      Array.isArray(filter) ? filter.map((filter) => filter.value) : []
    );
    filter.map((x) => activeOrderFilter.includes(x.value));
    setFilterCompleted(!filterCompleted);
  };

  /**
   * @param {Array} filter - This is containing array of filters from payment status
   */
  const handlePaymentFilterChange = (filter) => {
    setPage(1);
    setActivePaymentFilter(
      Array.isArray(filter) ? filter.map((filter) => filter.value) : []
    );
    setFilterCompleted(!filterCompleted);
  };

  /**
   * @param {Array} filter - This is containing array of filters from delivery status
   */
  const handleDeliveryFilterChange = (filter) => {
    setPage(1);
    setActiveDeliveryFilter(
      Array.isArray(filter) ? filter.map((filter) => filter.value) : []
    );
    setFilterCompleted(!filterCompleted);
  };

  const handleDaNameFilterChange = (filter) => {
    setPage(1);
    setActiveDaNameFilter(filter ? filter.value : null);
    setFilterCompleted(!filterCompleted);
  };

  const changePage = (event, value) => {
    setPage(value);
  };

  const handleChangeCopyTextToCopied = () => {
    setCopyText("Copied!");
  };

  const handleChangeCopyTextToCopy = () => {
    setTimeout(() => {
      setCopyText("Copy");
    }, 100);
  };

  const handleDateFromChange = (date) => {
    setPage(1);
    let newFromDate = new Date(
      date.toString().replace(/[0-9][0-9]:.* GMT/, "00:00:00 GMT")
    );
    clickedRefFrom.current = true;
    setFromDate(newFromDate);
    onDateEpochChange(
      String(getDateEpoch(newFromDate)).slice(0, 10),
      "ChangeFrom"
    );
  };

  const handleDateToChange = (date) => {
    setPage(1);
    let newToDate = new Date(
      date.toString().replace(/[0-9][0-9]:.* GMT/, "23:59:59 GMT")
    );
    clickedRefTo.current = true;
    setToDate(newToDate);
    onDateEpochChange(String(getDateEpoch(newToDate)).slice(0, 10), "ChangeTo");
  };

  const handleDateFromChangeCreated = (date) => {
    setPage(1);
    let newFromDate = new Date(
      date.toString().replace(/[0-9][0-9]:.* GMT/, "00:00:00 GMT")
    );
    clickedRefFromCreated.current = true;
    setFromDateCreated(newFromDate);
    onDateEpochChange(
      String(getDateEpoch(newFromDate)).slice(0, 10),
      "ChangeFromCreated"
    );
  };

  const handleDateToChangeCreated = (date) => {
    setPage(1);
    let newToDate = new Date(
      date.toString().replace(/[0-9][0-9]:.* GMT/, "23:59:59 GMT")
    );
    clickedRefToCreated.current = true;
    setToDateCreated(newToDate);
    onDateEpochChange(
      String(getDateEpoch(newToDate)).slice(0, 10),
      "ChangeToCreated"
    );
  };

  const onDateEpochChange = (val, check) => {
    if (check === "ChangeFrom") {
      setFromEpochDate(val);
    } else if (check === "ChangeTo") {
      setToEpochDate(val);
    } else if (check === "ChangeFromCreated") {
      setFromEpochDateCreated(val);
    } else if (check === "ChangeToCreated") {
      setToEpochDateCreated(val);
    }
    setFilterCompleted(!filterCompleted);
  };

  const handleValueChangeBusinessName = (e) => {
    let enteredBusinessName = e.target.value;
    setPage(1);
    setTextValueBusinessName(enteredBusinessName);
  };

  const handleValueChangePhoneNumber = (enteredSearchValue) => {
    let hasIndianCountryCode = false;
    let hasValidPhoneNumber = false;
    let enteredSearchValueIsNull =
      enteredSearchValue === undefined ? true : false;

    if (!enteredSearchValueIsNull) {
      let phoneNumberDetails = parsePhoneNumber(enteredSearchValue);
      if (
        phoneNumberDetails != undefined &&
        phoneNumberDetails.country == "IN"
      ) {
        hasIndianCountryCode = true;
      }

      hasValidPhoneNumber = isValidPhoneNumber(enteredSearchValue);
    }

    if (
      enteredSearchValueIsNull ||
      (hasValidPhoneNumber && hasIndianCountryCode)
    ) {
      setPage(1);
      setTextValuePhoneNumber(enteredSearchValue);
    }
  };
  const handleValueChangeOrder = (e) => {
    setTextValueOrder(e.target.value);
  };

  const handleDisplayFilters = () => {
    setIsCollapsed((prev) => !prev);
  };

  const handleDisplayTableCell = (tableCellId) => {
    return localStorage.getItem(`${tableCellId}`) === "false" ? "none" : null;
  };

  const handleRefreshFilters = () => {
    setFromEpochDate();
    setToEpochDate();
    setFromEpochDateCreated();
    setToEpochDateCreated();
    setFromDate(null);
    setToDate(null);
    setFromDateCreated(null);
    setToDateCreated(null);
    setTextValueBusinessName("");
    setTextValuePhoneNumber("");
    setTextValueOrder("");
    setPage(1);
    setFilterCompleted(!filterCompleted);
    setDownloadData(false);
  };

  const handleEyeButtonDropdownToggle = () => {
    setEyeButtonDropdownIsOpen(
      (eyeButtonDropdownPrevOpen) => !eyeButtonDropdownPrevOpen
    );
  };

  useEffect(() => {
    eyeButtonDropdownPrevOpen.current === true &&
    eyeButtonDropdownIsOpen === false
      ? eyeButonDropdownAnchorRef.current.focus()
      : (eyeButtonDropdownPrevOpen.current = eyeButtonDropdownIsOpen);
  }, [eyeButtonDropdownIsOpen]);

  const handleSearchFilters = () => {
    axios
      .get(config.getOrderListUrl, {
        headers: {
          Authorization: "JWT " + { authToken }.authToken,
        },
        params: {
          business_name: textValueBusinessName,
          customer_phone: textValuePhoneNumber,
          order_short_number: textValueOrder,
          circle_id: { circleId }.circleId,
          order_status: activeOrderFilter.join(),
          page: page,
          delivery_type: activeDeliveryFilter.join(),
          payment_status: activePaymentFilter.join(),
          delivery_agent_id: activeDaNameFilter,
          order_status_dt_modified_from: fromEpochDate,
          order_status_dt_modified_to: toEpochDate,
          dt_from: fromEpochDateCreated,
          dt_to: toEpochDateCreated,
        },
      })
      .then((response) => {
        setTableData(response.data.results);
        setorderCount(response.data.count);
      })
      .catch(function (error) {
        alert("Unauthorize or slow internet");
      });
  };
  useEffect(() => {
    handleSearchFilters();
  }, [
    textValueBusinessName,
    textValuePhoneNumber,
    textValueOrder,
    filterCompleted,
    page,
  ]);
  useEffect(() => {
    if (
      !localStorage.getItem("userAuthToken") ||
      localStorage.getItem("userAuthToken") != { authToken }.authToken
    ) {
      deleteFcmTokenInstance({ authToken }.authToken);
      localStorage.setItem("userAuthToken", { authToken }.authToken);
    }
  }, []);
  const downloadAPI = () => {
    axios
      .get(config.downloadOrderListUrl, {
        headers: {
          Authorization: "JWT " + { authToken }.authToken,
        },
        params: {
          business_name: textValueBusinessName,
          customer_phone: textValuePhoneNumber,
          order_short_number: textValueOrder,
          circle_id: { circleId }.circleId,
          order_status: activeOrderFilter.join(),
          delivery_type: activeDeliveryFilter.join(),
          payment_status: activePaymentFilter.join(),
          delivery_agent_id: activeDaNameFilter,
          order_status_dt_modified_from: fromEpochDate,
          order_status_dt_modified_to: toEpochDate,
          dt_from: fromEpochDateCreated,
          dt_to: toEpochDateCreated,
        },
      })
      .then((response) => {
        alert("Confirm Download");
        console.log(`download data =>${response.data}`);
        alert(
          "Order Download is in progress, you will be notified when it is ready to download."
        );
        setDownloadData(true);
      })
      .catch(function (error) {
        alert("Unauthorize or slow internet");
      });
  };

  const notificationAPI = (clusterID) => {
    axios
      .get(config.getOrderListUrl + clusterID + "/notifications", {
        headers: {
          Authorization: "JWT " + { authToken }.authToken,
        },
        params: {
          title: textValueTitle,
          body: textValueNotificationMessage,
          customer_phone: [textValueCustomerPhoneNumber],
        },
      })
      .then((response) => {})
      .catch(function (error) {
        alert("Unauthorize or slow internet");
      });
  };
  const rows = tableData.map((item, key) => {
    let smart_box_manual_delivery = constNotAvailable;
    let smartbox_info = item.order.smartbox_info;
    if (smartbox_info != null && smartbox_info.smartbox_manual_delivery) {
      smart_box_manual_delivery = {
        smartbox_delivery: constTrue,
        smartbox_id: smartbox_info.smartbox_id,
        smartbox_compartment_id: smartbox_info.compartment_id,
        pretty_address: smartbox_info.smartbox_address.pretty_address,
      };
    }

    return {
      id: item.order.order_short_number,
      Order_created_date: getDateFormat(item.order.created),
      last_update: getDateFormat(item.order.order_status_modified_dt),
      order_time_elapsed: differenceBetweenOrderCreatedOrderUpdated(
        item.order.created,
        item.order.order_status_modified_dt,
        item.order.order_status
      ),
      is_ondc_order: is_ondc_order_conversion(item.order.is_ondc_order),
      Status: removeUnderscoreConvertInSmallcase(item.order.order_status),
      Seller: item.order.business_name,
      Order_amt: convertPaisaToRupee(item.order.order_total),
      Delivery_type: removeUnderscoreConvertInSmallcase(
        item.order.delivery_type
      ),
      Delivery_charges: convertPaisaToRupee(item.order.delivery_charges),
      Other_charges: convertPaisaToRupee(item.order.other_charges),
      Item_Total: convertPaisaToRupee(item.order.item_total),
      Delivery_Agent: getDeliveryAgentName(item.order.da_info),
      Payment_Status: removeUnderscoreConvertInSmallcase(
        item.order.payment_info.status
      ),
      Payment_mode: item.order.payment_info.via,
      Customer_name: item.order.customer_name,
      Customer_number: item.order.customer_phones,
      Customer_address: getCustomerAddress(item.order.delivery_address),
      long_id: item.order.order_id,
      Order_Type: removeUnderscoreConvertInSmallcase(item.order.order_type),
      display: "none",
      delivery_status: removeUnderscoreConvertInSmallcase(
        item.order.delivery_status
      ),
      smart_box_manual_delivery: smart_box_manual_delivery,
    };
  });

  const handleCloseModalWithOrderDetail = () => {
    setOrderDataDisplayModalOpen(false);
  };

  const handleOrderDataDisplayOpenModal = (orderDataForModal) => {
    setModalOrderData(orderDataForModal.data.results[0].order);
    setOrderDataDisplayModalOpen(true);
  };

  const handleGetOrderDataByShortID = (orderShortId) => {
    axios
      .get(config.getOrderListUrl, {
        headers: {
          Authorization: "JWT " + { authToken }.authToken,
        },
        params: {
          circle_id: { circleId }.circleId,
          order_short_number: orderShortId,
        },
      })
      .then((response) => {
        handleOrderDataDisplayOpenModal(response);
      })
      .catch(function (error) {
        console.log(`an error occured ${error}`);
      });
  };

  useEffect(() => {
    const handleDisplayProcessingQueue = async () => {
      try {
        await axios
          .get(
            `https://api.esamudaay.com/api/v2/orders/download/status?circle_id=68e5ffc3-4359-4c28-9c8d-6d93077be313`,
            {
              headers: {
                Authorization: "JWT " + { authToken }.authToken,
              },
            }
          )
          .then((response) => {
            setProcessedQueuedResponse(response);
            console.log(response);
          });
      } catch (error) {
        console.log(error);
      }
    };

    handleDisplayProcessingQueue();

    const intervalId = setInterval(handleDisplayProcessingQueue, 5000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <Navbar
        processedQueuedResponse={processedQueuedResponse}
        downloadData={downloadData}
        setDownloadData={setDownloadData}
        isNewNotificationPopped={isNewNotificationPopped}
        onSetNewNotificationPopped={onSetNewNotificationPopped}
      />
      <OrderModal
        key={modalOrderData.order_id}
        orderDataDisplayModalOpen={orderDataDisplayModalOpen}
        modalOrderData={modalOrderData}
        onHandleCloseModalWithOrderDetail={handleCloseModalWithOrderDetail}
        onHandleChangeCopyTextToCopied={handleChangeCopyTextToCopied}
        onHandleChangeCopyTextToCopy={handleChangeCopyTextToCopy}
        onHandleGetOrderDataByShortID={handleGetOrderDataByShortID}
        copyText={copyText}
      />
      <div className={classes.root}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          style={{ margin: "20px 0" }}
        >
          <Box
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box style={{ marginRight: "10px" }}>
              <BootstrapButton
                ref={eyeButonDropdownAnchorRef}
                aria-controls={
                  eyeButtonDropdownIsOpen ? "menu-list-grow" : undefined
                }
                aria-haspopup="true"
                onClick={handleEyeButtonDropdownToggle}
              >
                <VisibilityIcon />
              </BootstrapButton>
            </Box>
            <EyeButtonDropdown
              eyeButonDropdownAnchorRef={eyeButonDropdownAnchorRef}
              setEyeButtonDropdownIsOpen={setEyeButtonDropdownIsOpen}
              eyeButtonDropdownIsOpen={eyeButtonDropdownIsOpen}
            />
            <Box>
              <BootstrapButton onClick={handleDisplayFilters}>
                <FilterListIcon />
              </BootstrapButton>
            </Box>
            <Box style={{ marginLeft: "10px" }}>
              <Tooltip
                title={
                  toDate || toDateCreated !== null
                    ? "Download Data"
                    : "Please add dates from filter to download data."
                }
                arrow
              >
                <span>
                  <BootstrapButton
                    disabled={toDate || toDateCreated !== null ? false : true}
                    variant="contained"
                    color="primary"
                    onClick={downloadAPI}
                  >
                    <GetAppIcon />
                  </BootstrapButton>
                </span>
              </Tooltip>
            </Box>
            <Box
              display="flex"
              justifyContent="flex-end"
              style={{ marginLeft: "10px" }}
            >
              <BootstrapButton
                variant="contained"
                color="secondary"
                onClick={handleRefreshFilters}
              >
                <RefreshIcon />
              </BootstrapButton>
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <Box style={{ margin: "0 5px" }}>
              <InputBox
                onValueChange={handleValueChangeOrder}
                textValue={textValueOrder}
                id="order-short-number-search"
                placeholder="Search by order ID..."
              />
            </Box>
            <Box>
              <Counter orderCount={orderCount} />
            </Box>
          </Box>
        </Box>
        <FiltersCollapse
          onHandleDateFromChange={handleDateFromChange}
          onHandleDateToChange={handleDateToChange}
          onHandleDateFromChangeCreated={handleDateFromChangeCreated}
          onHandleDateToChangeCreated={handleDateToChangeCreated}
          onHandleOrderFilterChange={handleOrderFilterChange}
          onHandleDeliveryFilterChange={handleDeliveryFilterChange}
          onHandlePaymentFilterChange={handlePaymentFilterChange}
          onHandleDaNameFilterChange={handleDaNameFilterChange}
          onHandleValueChangeBusinessName={handleValueChangeBusinessName}
          onHandleValueChangePhoneNumber={handleValueChangePhoneNumber}
          toDate={toDate}
          fromDate={fromDate}
          toDateCreated={toDateCreated}
          fromDateCreated={fromDateCreated}
          clickedRefFrom={clickedRefFrom}
          clickedRefTo={clickedRefTo}
          clickedRefFromCreated={clickedRefFromCreated}
          clickedRefToCreated={clickedRefToCreated}
          isCollapsed={isCollapsed}
          textValueBusinessName={textValueBusinessName}
          textValuePhoneNumber={textValuePhoneNumber}
          circleId={circleId}
          authToken={authToken}
        />
        <Box style={{ marginTop: "0em" }}>
          <Box spacing={3}>
            <Box>
              <TableContainer
                component={Paper}
                style={{
                  height: "100%",
                  width: "100%",
                  display: "block",
                  overflow: "auto",
                }}
              >
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {homepageTableColumns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            display: handleDisplayTableCell(column.id),
                          }}
                        >
                          <strong>{column.label}</strong>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => {
                      return row ? (
                        <TableRow key={row.id}>
                          <TableCell>
                            <Box
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Box
                                onClick={() =>
                                  handleGetOrderDataByShortID(row.id)
                                }
                                style={{
                                  cursor: "pointer",
                                  color: "#0000ff",
                                  display: handleDisplayTableCell("id"),
                                }}
                                align="left"
                              >
                                <strong>{row.id}</strong>
                              </Box>
                              <Box
                                onClick={handleChangeCopyTextToCopied}
                                onMouseOver={handleChangeCopyTextToCopy}
                              >
                                <Tooltip title={copyText} aria-label="Copy">
                                  <CopyToClipboard text={row.id}>
                                    <FileCopyIcon
                                      style={{
                                        height: "16px",
                                        cursor: "pointer",
                                        color: "grey",
                                      }}
                                    />
                                  </CopyToClipboard>
                                </Tooltip>
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell
                            style={{
                              display:
                                handleDisplayTableCell("Order_created_date"),
                            }}
                            align="left"
                          >
                            {row.Order_created_date}
                          </TableCell>
                          <TableCell
                            style={{
                              display: handleDisplayTableCell("last_update"),
                            }}
                            align="left"
                          >
                            {row.last_update}
                          </TableCell>
                          <TableCell
                            style={{
                              display:
                                handleDisplayTableCell("order_time_elapsed"),
                            }}
                            align="left"
                          >
                            {row.order_time_elapsed}
                          </TableCell>
                          <TableCell
                            style={{
                              display: handleDisplayTableCell("is_ondc_order"),
                            }}
                            align="left"
                          >
                            {row.is_ondc_order}
                          </TableCell>
                          <TableCell>
                            <Box
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Box
                                style={{
                                  display: handleDisplayTableCell("Status"),
                                  width: "140px",
                                }}
                                align="left"
                              >
                                <Box
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    position: "relative",
                                  }}
                                >
                                  <Box>{row.Status}</Box>
                                  <Box
                                    style={{
                                      position: "absolute",
                                      right: "0",
                                      top: "2px",
                                    }}
                                  >
                                    {row.Status ===
                                    constCustomerPendingStatus ? (
                                      <Tooltip
                                        title={
                                          <div style={{ fontSize: "13px" }}>
                                            {constHelpText}
                                          </div>
                                        }
                                      >
                                        <InfoIcon
                                          style={{
                                            height: "16px",
                                            color: "grey",
                                          }}
                                        />
                                      </Tooltip>
                                    ) : null}
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell
                            style={{
                              display:
                                handleDisplayTableCell("delivery_status"),
                            }}
                            align="left"
                          >
                            {row.delivery_status}
                          </TableCell>
                          <TableCell
                            style={{
                              display: handleDisplayTableCell("Seller"),
                            }}
                            align="left"
                          >
                            {row.Seller}
                          </TableCell>
                          <TableCell
                            style={{
                              display: handleDisplayTableCell("Order_amt"),
                            }}
                            align="left"
                          >
                            {row.Order_amt}
                          </TableCell>
                          <TableCell
                            style={{
                              display: handleDisplayTableCell("Delivery_Type"),
                            }}
                            align="left"
                          >
                            {row.Delivery_type}
                          </TableCell>
                          <TableCell
                            style={{
                              display:
                                handleDisplayTableCell("Delivery_Charges"),
                            }}
                            align="left"
                          >
                            {row.Delivery_charges}
                          </TableCell>
                          <TableCell
                            style={{
                              display: handleDisplayTableCell("Other_charges"),
                            }}
                            align="left"
                          >
                            {row.Other_charges}
                          </TableCell>
                          <TableCell
                            style={{
                              display: handleDisplayTableCell("Item_Total"),
                            }}
                            align="left"
                          >
                            {row.Item_Total}
                          </TableCell>
                          <TableCell
                            style={{
                              display: handleDisplayTableCell("Delivery_Agent"),
                            }}
                            align="left"
                          >
                            {row.Delivery_Agent}
                          </TableCell>
                          <TableCell
                            style={{
                              display: handleDisplayTableCell("Payment_Status"),
                            }}
                            align="left"
                          >
                            {row.Payment_Status}
                          </TableCell>
                          <TableCell
                            style={{
                              display: handleDisplayTableCell("Payment_mode"),
                            }}
                            align="left"
                          >
                            {row.Payment_mode}
                          </TableCell>

                          <TableCell
                            style={{
                              display: handleDisplayTableCell("Customer_name"),
                            }}
                            align="left"
                          >
                            {row.Customer_name}
                          </TableCell>
                          <TableCell
                            style={{
                              display:
                                handleDisplayTableCell("Customer_number"),
                            }}
                            align="left"
                          >
                            {row.Customer_number}
                          </TableCell>
                          <TableCell
                            style={{
                              display:
                                handleDisplayTableCell("Customer_address"),
                            }}
                            align="left"
                          >
                            {row.Customer_address}
                          </TableCell>
                          <TableCell
                            style={{
                              display: handleDisplayTableCell("Order_Type"),
                            }}
                            align="left"
                          >
                            {row.Order_Type}
                          </TableCell>
                          <TableCell>
                            <Box
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Box
                                style={{
                                  display: handleDisplayTableCell("long_id"),
                                }}
                                align="left"
                              >
                                {row.long_id}
                              </Box>
                              <Box
                                onClick={handleChangeCopyTextToCopied}
                                onMouseOver={handleChangeCopyTextToCopy}
                              >
                                <Tooltip title={copyText} aria-label="Copy">
                                  <CopyToClipboard text={row.long_id}>
                                    <FileCopyIcon
                                      style={{
                                        height: "16px",
                                        cursor: "pointer",
                                        color: "grey",
                                      }}
                                    />
                                  </CopyToClipboard>
                                </Tooltip>
                              </Box>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ) : null;
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box display="flex" justifyContent="center">
                <Pagination
                  style={{
                    paddingTop: "20px",
                    scrollPaddingBottom: "20px",
                    alignContent: "center",
                  }}
                  count={Math.ceil(orderCount / 10)}
                  page={parseInt(page)}
                  onChange={changePage}
                  showFirstButton
                  showLastButton
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </div>
    </>
  );
};

export default Content;
